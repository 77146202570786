import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/index.scss'
import '../styles/_responsive.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {  } from '@fortawesome/free-brands-svg-icons'
import { faLongArrowAltRight, faLongArrowAltLeft, faTimesCircle, faAngleDown, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'

const prefix_string = "bs";

library.add(faLongArrowAltRight, faLongArrowAltLeft, faTimesCircle, faAngleDown, faPhone, faCheckSquare)

const LayoutBs = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryBs {
        site {
          siteMetadata {
            title
            description
            image
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const image = seo.image;
      
      return(
      <>
      {/*    <Helmet>
      <title>WELL - Digitalni Marketing, Web stranice, Google Oglašavanje, Društvene mreže</title>
        <meta name="description" content='Bilo da trebate klasični ili digitalni marketing, web stranice, Google oglase, kampanje na društvenim mrežama ili konzalting, WELL Vam je uvijek na raspolaganju sa svojim stručnim osobljem.' />
        <meta name="image" content={image} />
        <meta property="og:image" content={image} />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:type" content='website' />
        <meta property="og:description" content='Agencija za Web i Digitalni Marketing' />
        <meta property="og:url" content='https://wellpromotion.ba' />
        <meta name="twitter:card" content={image} />
       <meta property="twitter:image:alt" content='Well Business Promotion Marketing Agencija' /> 
          <html lang={prefix_string} />
        </Helmet>*/}
        <div>
          {children}
        </div>
      </>
    );
  }}
  />
)

LayoutBs.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutBs
